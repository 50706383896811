import { DialogTrigger } from '@troon/ui';
import dayjs from '@troon/dayjs';
import { Icon } from '@troon/icons';
import { TeeTimeAlertDialog } from '../../course/[facilityId]/(sub)/reserve-tee-time/components/tee-time-alert';

type NoTeeTimeProps = {
	players: number | undefined;
	date: string;
	startAt: number;
	endAt: number;
};

export function NoTeeTimes(props: NoTeeTimeProps) {
	return (
		<div class="flex flex-row items-center gap-2 rounded bg-neutral-100 p-2 pe-2 ps-4 text-neutral-800">
			<Icon name="info" class="shrink-0" />
			<p class="block shrink grow text-sm md:text-base">No tee times available that match your playing preferences.</p>
			<TeeTimeAlertDialog
				selectedDay={dayjs(props.date).toDate()}
				selectedPlayers={props.players}
				selectedTime={[props.startAt, props.endAt]}
			>
				<DialogTrigger class="ms-4 grow-0 rounded-md p-2">
					<Icon name="bell-ring" />
					<span class="sr-only">Set a tee time alert</span>
				</DialogTrigger>
			</TeeTimeAlertDialog>
		</div>
	);
}

import { Icon } from '@troon/icons';

export function OutsideBookingWindow() {
	return (
		<div class="flex flex-row items-center gap-2 rounded bg-neutral-100 p-2 pe-2 ps-4 text-neutral-800">
			<Icon name="info" class="shrink-0" />
			<p class="block shrink grow text-sm md:text-base">
				The selected date is outside the booking window for this course.
			</p>
		</div>
	);
}

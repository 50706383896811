import { Show } from 'solid-js';
import { TextLink } from '@troon/ui';
import { Icon } from '@troon/icons';

export function NonBookable(props: { phone?: string | null }) {
	return (
		<div class="flex flex-row items-center gap-2 rounded bg-neutral-100 p-2 pe-2 ps-4 text-neutral-800">
			<Icon name="info" class="shrink-0" />
			<p class="block shrink grow text-sm md:text-base">
				Please call the course{' '}
				<Show when={props.phone}>
					at <TextLink href={`tel:${props.phone}`}>{props.phone}</TextLink>
				</Show>{' '}
				to book.
			</p>
		</div>
	);
}

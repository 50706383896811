import { Button } from '@troon/ui';
import { For } from 'solid-js';
import { Icon } from '@troon/icons';
import { useUser } from '../../../providers/user';
import type { IPlace } from '../../../components/search-locations';
import type { SearchPlaceFragment } from '../../../graphql';

type Props = {
	onSelectLocation: (info: IPlace) => void;
};

export function EmptyState(props: Props) {
	const user = useUser();

	return (
		<div class="flex w-full flex-col items-center gap-4 py-8">
			<Icon name="map-pin" class="text-8xl text-brand" />
			<h2 class="text-center text-xl font-semibold">Search by location</h2>
			<p class="text-center text-lg text-neutral-800">Enter a location to find courses.</p>
			<ul class="flex flex-row flex-wrap justify-center gap-4">
				<For each={getPopularLocations(user()?.me.card?.id)}>
					{(loc) => (
						<li>
							<Button
								appearance="secondary"
								class="normal-case"
								onClick={() => {
									props.onSelectLocation(loc);
								}}
							>
								{loc.displayValue}
							</Button>
						</li>
					)}
				</For>
			</ul>
		</div>
	);
}

const popularLocations: Record<string, Array<SearchPlaceFragment>> = {
	az: [
		{
			countryName: 'USA',
			name: 'Scottsdale',
			regionName: 'Arizona',
			coordinates: { latitude: 33.4956, longitude: -111.9182 },
		},
		{
			countryName: 'USA',
			name: 'Tucson',
			regionName: 'Arizona',
			coordinates: { latitude: 32.2545, longitude: -110.984 },
		},
	],
	ny: [
		{
			countryName: 'USA',
			name: 'New York',
			regionName: 'New York',
			coordinates: { latitude: 40.715, longitude: -74.00715 },
		},
	],
	fl: [
		{
			countryName: 'USA',
			name: 'Naples',
			regionName: 'Florida',
			coordinates: { latitude: 26.14212, longitude: -81.79419 },
		},
		{
			countryName: 'USA',
			name: 'Orlando',
			regionName: 'Florida',
			coordinates: { latitude: 28.5351, longitude: -81.38558 },
		},
		{
			countryName: 'USA',
			name: 'Tampa',
			regionName: 'Florida',
			coordinates: { latitude: 27.9592, longitude: -82.468 },
		},
	],
	tucson: [
		{
			countryName: 'USA',
			name: 'Tucson',
			regionName: 'Arizona',
			coordinates: { latitude: 32.2545, longitude: -110.984 },
		},
	],
	hi: [
		{
			countryName: 'USA',
			name: 'Maui',
			regionName: 'Hawaii',
			coordinates: { latitude: 20.79714, longitude: -156.3325 },
		},
		{
			countryName: 'USA',
			name: 'Honolulu',
			regionName: 'Hawaii',
			coordinates: { latitude: 21.310742, longitude: -157.85366 },
		},
	],
	westcoast: [
		{
			countryName: 'USA',
			name: 'Palm Springs',
			regionName: 'CA',
			coordinates: { latitude: 33.8312, longitude: -116.5414 },
		},
		{
			countryName: 'USA',
			name: 'Bend',
			regionName: 'Oregon',
			coordinates: { latitude: 44.05863, longitude: -121.31842 },
		},
		{
			countryName: 'USA',
			name: 'Los Angeles',
			regionName: 'CA',
			coordinates: { latitude: 34.05417, longitude: -118.27307 },
		},
		{
			countryName: 'USA',
			name: 'San Francisco',
			regionName: 'CA',
			coordinates: { latitude: 37.7752, longitude: -122.436366 },
		},
	],
	northeast: [
		{
			countryName: 'USA',
			name: 'New York',
			regionName: 'New York',
			coordinates: { latitude: 40.715, longitude: -74.00715 },
		},
		{
			countryName: 'USA',
			name: 'Atlantic City',
			regionName: 'NJ',
			coordinates: { latitude: 39.364792, longitude: -74.42312 },
		},
	],
	southeast: [
		{
			countryName: 'USA',
			name: 'Naples',
			regionName: 'Florida',
			coordinates: { latitude: 26.14212, longitude: -81.79419 },
		},
		{
			countryName: 'USA',
			name: 'Orlando',
			regionName: 'Florida',
			coordinates: { latitude: 28.5351, longitude: -81.38558 },
		},
	],
	ga: [
		{
			countryName: 'USA',
			name: 'Atlanta',
			regionName: 'Georgia',
			coordinates: { latitude: 33.75107, longitude: -84.3858 },
		},
	],
};
const popularLocationsFallback: Array<SearchPlaceFragment> = [
	{
		countryName: 'USA',
		name: 'Scottsdale',
		regionName: 'Arizona',
		coordinates: { latitude: 33.4956, longitude: -111.9182 },
	},
	{
		countryName: 'USA',
		name: 'New York',
		regionName: 'New York',
		coordinates: { latitude: 40.715, longitude: -74.00715 },
	},
	{
		countryName: 'USA',
		name: 'Palm Springs',
		regionName: 'CA',
		coordinates: { latitude: 33.8312, longitude: -116.5414 },
	},
	{
		countryName: 'USA',
		name: 'Naples',
		regionName: 'Florida',
		coordinates: { latitude: 26.14212, longitude: -81.79419 },
	},
];

function mapPlaceToLocation(loc: SearchPlaceFragment) {
	return {
		value: `ll-${loc.coordinates.latitude}-${loc.coordinates.longitude}`.replace(/[^\w]+/g, '-'),
		displayValue: [loc.name, loc.regionName].join(', '),
		itemType: 'place',
		...loc,
	} satisfies IPlace;
}

export function getPopularLocations(card?: string) {
	return (card ? (popularLocations[card] ?? popularLocationsFallback) : popularLocationsFallback).map(
		mapPlaceToLocation,
	);
}

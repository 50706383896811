import { useSearchParams } from '@solidjs/router';
import { Icon } from '@troon/icons';
import { Button, Link } from '@troon/ui';

type ShowAllProps = {
	name: string;
	facilitySlug: string;
};
export function ShowAll(props: ShowAllProps) {
	const [params] = useSearchParams();
	return (
		<Button
			as={Link}
			appearance="transparent"
			href={`/course/${props.facilitySlug}/reserve-tee-time/?${new URLSearchParams(params as Record<string, string>).toString()}`}
			class="flex size-full h-full flex-col items-center justify-center gap-2 px-4 normal-case"
		>
			<Icon name="arrow-circle-right" class="text-2xl" />
			<span class="text-nowrap">
				See all <span class="sr-only">tee times for {props.name}</span>
			</span>
		</Button>
	);
}
